import React from 'react';
import "./employee.css";

const BusinessUnitDisplay = ({ value, businessUnits }) => {

  if (!Array.isArray(value) || !Array.isArray(businessUnits)) {
    return <div>Invalid data</div>;
  }

  const buNames = value
    .map(id => {
      const bu = businessUnits.find(bu => bu.businessUnitID === id);
      return bu?.name;
    })
    .filter(Boolean);

  if (buNames.length > 3) {
    return <div>{buNames[0]}, {buNames[1]}, {buNames[2]} <span className="badge">+{buNames.length - 3}</span></div>;
  }

  if (buNames.length === 0) {
    return <div>No business units</div>;
  }

  return (
    <div>
      {buNames.join(', ')}
    </div>
  );
};

export default BusinessUnitDisplay;