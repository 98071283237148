import { SERVER_URL } from './config.js';
const employeeEndpoint = `${SERVER_URL}/api/employees`;

//get all
export const getEmployees = async () => {
  const businessID = localStorage.getItem('businessId');
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${employeeEndpoint}/get-all/${businessID}`,
      {
        method: "GET",
        headers: { 'Authorization': `Bearer ${token}` },
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.employees;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};

//update
export const updateEmployee = (updatedEmployee, employeeId) => {
  const token = localStorage.getItem('token');
  fetch(`${employeeEndpoint}/${employeeId}`, {
    method: "PUT", 
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(updatedEmployee),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to update employee");
      }
      console.log("Employee updated successfully");
    })
    .catch((error) => {
      console.error("Error updating employee:", error);
    });
}


//delete  by ID
export const deleteEmployee = (employeeID) => {
  const token = localStorage.getItem('token');
  return fetch(`${employeeEndpoint}/${employeeID}`, {
    method: 'DELETE',
    'Access-Control-Allow-Origin': '*',
    headers: { 'Authorization': `Bearer ${token}` }
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to delete employee');
      }
    })
    .catch(error => {
      console.error('Error deleting employee:', error);
      throw error; 
    });
  };


//create
export const createEmployee = (newEmployee) => {
  const token = localStorage.getItem('token');
  const businessID = localStorage.getItem('businessId');
  const requestEmployee = {
    firstName: newEmployee.firstName,
    lastName: newEmployee.lastName,
    contractID: newEmployee.contractID,
    businessUnits: newEmployee.businessUnits,
    skillRate: newEmployee.skillRate,
    businessID: businessID,
  }
  return fetch(`${employeeEndpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(requestEmployee)
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Failed to create employee');
    }
    console.log('New employee created successfully');
  })
  .catch(error => {
    console.error('Error creating employee:', error);
  });
};