import React, { useState, useCallback, useMemo } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import UserPanel from "../user-panel/UserPanel";
import "./Header.scss";
import { Template } from "devextreme-react/core/template";
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import service from "../../dictionary.js";

export default function Header({ menuToggleEnabled, title, toggleMenu }) {
  const locales = service.getLocales();
  const [localeState, setLocaleState] = useState(
    sessionStorage.getItem("locale") || "en"
  );

  const changeLocale = useCallback((e) => {
    const newLocale = e.itemData.Value;
    sessionStorage.setItem("locale", newLocale);
    setLocaleState(newLocale);
    document.location.reload();
  }, []);

  const languageMenuItems = useMemo(() => 
    locales.map(locale => ({
      text: locale.Name,
      Value: locale.Value,
      icon: locale.Value === localeState ? 'check' : 'empty'
    })),
  [locales, localeState]);

  return (
    <header className={"header-component"}>
      <Toolbar className={"header-toolbar"}>
        <Item
          visible={menuToggleEnabled}
          location={"before"}
          widget={"dxButton"}
          cssClass={"menu-button icon-white"}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item
          location={"before"}
          cssClass={"header-title"}
          text={title}
          visible={!!title}
        />
        <Item
          location={"after"}
          locateInMenu={"auto"}
          menuItemTemplate={"languageSelectorTemplate"}
        >
          <Button
            className={"language-button"}
            width={90}
            height={"100%"}
            stylingMode={"text"}
          >
            <div className="language-button-content">
              <i className="dx-icon-globe" />
              <span className="language-code">{localeState.toUpperCase()}</span>
            </div>
          </Button>
          <ContextMenu
            items={languageMenuItems}
            target={".language-button"}
            onItemClick={changeLocale}
            showEvent={"dxclick"}
            width={100}
            cssClass={"language-menu"}
          >
            <Position my={{ x: 'left', y: 'top' }} at={{ x: 'left', y: 'bottom' }} />
          </ContextMenu>
        </Item>
        <Item
          location={"after"}
          locateInMenu={"auto"}
          menuItemTemplate={"userPanelTemplate"}
        >
          <Button
            className={"user-button authorization"}
            width={210}
            height={"100%"}
            stylingMode={"text"}
          >
            <UserPanel menuMode={"context"} />
          </Button>
        </Item>
        <Template name={"userPanelTemplate"}>
          <UserPanel menuMode={"list"} />
        </Template>
        <Template name={"languageSelectorTemplate"}>
          <div>
            {locales.map(locale => (
              <div
                key={locale.Value}
                onClick={() => changeLocale({ itemData: locale })}
                style={{ cursor: 'pointer', padding: '8px' }}
              >
                {locale.Name}
              </div>
            ))}
          </div>
        </Template>
      </Toolbar>
    </header>
  );
}
