const locales = [
  {
    Name: "English",
    Value: "en",
  },
  {
    Name: "Slovensky",
    Value: "sk",
  },
];

const dictionary = {
  en: {
    //common
    copy: "Copy",
    delete: "Delete",
    pageSizeSelector: "Show page size selector",
    showInfo: "Show info",
    email: "Email",
    firstName: "First Name",
    lastName: "Last Name",
    requiredField: "Field is required",
    requiredMessage: "This field is required",
    edit: "Edit",
    emplTimeline: "Timeline Employees",
    unitTimeline: "Timeline Units",
    list: "Shift List",
    workWeek: "Work Week",
    submit: "Save",
    cancel: "Cancel",
    startDateValidationMessage: "Start date must be before the end date",
    endDateValidationMessage: "End date must be after the start date",
    commonErrorMsg: "Something went wrong. Please try again or contact technical support",
    //statistics
    statistics: "Statistics",
    leaveHoursPerMonth: "Total Leave Hours",
    currentTotalShiftHours: "Scheduled Shifts",
    workingHoursPerMonth: "Working Hours (Contract)",
    remainingWorkHours: "Remaining Working Hours",
    workingHours:"Working Hours (State)",
    filterByDateRange: "Date Range",
    date: "Date",
    category: "Category",
    hours: "Hours",
    shifts: "Shifts",
    leaves: "Leaves",
    //employess
    employees: "Employees",
    editEmployee: "Edit Employee",
    contract: "Contract",
    unit: "Unit",
    skillRate: "Skill Rate",
    positiveNumberMessage: "Skill Rate must be a positive number",
    delete: "Delete",
    pageSizeSelector: "Show page size selector",
    showInfo: "Show info",
    businessUnitValidationMsg: "At least one business unit must be selected.", 
    //units
    businessUnits: "Business Units",
    name: "Name",
    employeeCapacity: "Employee Capacity",
    dayOfWeek: "Day of Week",
    isOpen: "Is Open",
    openingStart: "Opening Start",
    openingEnd: "Opening End",
    editBusinessUnit: "Edit Business Unit",
    numberOfEmployees: "Number of Employees",
    positiveNumberMessage: "Must be a positive number",
    //units leave
    businessLeaves: "Business Leaves",
    editBusinessLeave: "Edit Business Leave",
    unit: "Unit",
    type: "Type",
    //employee leave
    employeeLeaves: "Employee Leaves",
    editEmployeeLeave: "Edit Employee Leave",
    //contracts
    contracts: "Contracts",
    editContract: "Edit Contract",
    contractName: "Contract Name",
    weekendDefinition: "Weekend Definition",
    shiftPattern: "Shift Pattern",
    hoursPerDay: "Hours Per Day",
    hoursPerWeek: "Hours Per Week",
    positiveNumberMessage: "Must be a positive number",
    //shifts
    scheduler: "Scheduler",
    generateShifts: "Generate shifts",
    generateShiftsBtn: "Generate",
    refill: "Refill",
    generateConfirmation: "Are you sure you want to generate shifts?",
    refillConfirmation: "Are you sure you want to refill shifts?",
    deleteConfirmation: "Are you sure you want to delete all records?",
    shiftUpdated: "Shift was updated successfully",
    shiftsCreated: "Shifts were created successfully",
    shiftsDeleted: "Shifts were deleted successfully",
    employee: "Employee",
    unit: "Unit",
    shiftStart: "Shift Start",
    shiftEnd: "Shift End",
    orderMode: "Order",
    month: "Month",
    selectMonth: "Select month",
    selectItem: "Select item",
    deleteMsg: "Are you sure you want to delete this record?",
    shiftNotRefilledMsg: "Cannot refill shifts",
    shiftNotGeneratedMsg: "Cannot generate shifts. Please ensure that you have created business units and employees, then try again",
    //profile
    myProfile: "My Profile",
    businessId: "Business ID",
    //empl preferences
    employeePairPreferences: "Shifts Preferences",
    employee1: "Employee 1",
    employee2: "Employee 2",
    separateShifts: "Separate Shifts",
  },
  sk: {
    //common
    email: "Email",
    firstName: "Meno",
    lastName: "Priezvisko",
    copy: "Kopírovať",
    delete: "Vymazať",
    showInfo: "Zobraziť informácie",
    requiredMessage: "Toto pole je povinné",
    positiveNumberMessage: "Hodnotenie zručností musí byť kladné číslo",
    edit: "Upraviť",
    month: "Mesiac",
    day: "Deň",
    emplTimeline: "Timeline zamestnanci",
    unitTimeline: "Timeline prevádzky",
    list: "Zoznam zmien",
    workWeek: "Pracovný týždeň",
    submit: "Uložiť",
    cancel: "Zrušiť",
    startDateValidationMessage: "Dátum začiatku musí byť pred dátumom konca",
    endDateValidationMessage: "Dátum konca musí byť po dátume začiatku",
    commonErrorMsg: "Niečo sa pokazilo. Skúste to znova alebo kontaktujte technickú podporu.",
    //statistics
    statistics: "Analýza",
    leaveHoursPerMonth: "Dovolenky spolu",
    currentTotalShiftHours: "Naplánované zmeny",
    workingHoursPerMonth: "Pracovný fond (zmluva)",
    remainingWorkHours: "Zostávajúce hodiny",
    workingHours:"Pracovný fond (štát)",
    filterByDateRange: "Dátumy",
    date: "Dátum",
    category: "Kategória",
    category: "Kategória",
    hours: "Hodiny",
    shifts: "Zmeny",
    leaves: "Voľná",
    //employess
    employees: "Zamestnanci",
    editEmployee: "Upraviť zamestnanca",
    contract: "Zmluva",
    unit: "Prevádzka",
    skillRate: "Hodnotenie zručností",
    pageSizeSelector: "Zobraziť výber veľkosti stránky",
    businessUnitValidationMsg: "Aspoň jedna prevádzka musí byť vybraná", 
    //units
    businessUnits: "Prevádzky",
    name: "Názov prevádzky",
    employeeCapacity: "Počet zamestnancov",
    dayOfWeek: "Deň v týždni",
    isOpen: "Otvorené",
    openingStart: "Začiatok",
    openingEnd: "Koniec",
    editBusinessUnit: "Upraviť prevádzku",
    numberOfEmployees: "Počet zamestnancov",
    positiveNumberMessage: "Musí byť kladné číslo",
    //units leave
    businessLeaves: "Prevádzkové voľno",
    editBusinessLeave: "Upraviť voľno",
    type: "Typ",
    pageSizeSelector: "Zobraziť výber veľkosti stránky",
    //employee leave
    employeeLeaves: "Dovolenky",
    editEmployeeLeave: "Upraviť dovolenku",
    //contracts
    contracts: "Zmluvy",
    editContract: "Upraviť zmluvu",
    contractName: "Názov zmluvy",
    weekendDefinition: "Víkendy",
    shiftPattern: "Rotacia zmien",
    hoursPerDay: "Hodiny - deň",
    hoursPerWeek: "Hodiny - týždeň",
    positiveNumberMessage: "Musí byť kladné číslo",
    pageSizeSelector: "Zobraziť výber veľkosti stránky",
    //shifts
    scheduler: "Plánovač zmien",
    generateShifts: "Generovať zmeny",
    generateShiftsBtn: "Generovať",
    refill: "Doplniť",
    generateConfirmation: "Naozaj chcete generovať zmeny?",
    refillConfirmation: "Naozaj chcete doplniť zmeny?",
    deleteConfirmation: "Naozaj chcete odstrániť všetky záznamy?",
    shiftUpdated: "Zmena bola úspešne aktualizovaná",
    shiftsCreated: "Zmeny boli úspešne vytvorené",
    shiftsDeleted: "Zmeny boli úspešne odstránené",
    employee: "Zamestnanec",
    unit: "Prevádzka",
    shiftStart: "Začiatok zmeny",
    shiftEnd: "Koniec zmeny",
    orderMode: "Poradie",
    month: "Mesiac",
    selectMonth: "Vyberte mesiac",
    selectItem: "Vyberte poradie",
    deleteMsg: "Naozaj chcete odstrániť tento záznam?",
    shiftNotRefilledMsg: "Nie je možné doplniť zmeny",
    shiftNotGeneratedMsg: "Nie je možné generovať zmeny. Overte, že máte vytvorené prevádzky a zamestnancov, a skúste znova",
    //profile
    myProfile: "Môj profil",
    businessId: "ID firmy",
    //empl preferences
    employeePairPreferences: "Preferencie zmien",
    employee1: "Zamestnanec 1",
    employee2: "Zamestnanec 2",
    separateShifts: "Oddelené zmeny",
  },
};
export default {
  getLocales() {
    return locales;
  },
  getDictionary() {
    return dictionary;
  },
};
