import React, { useState, useEffect, useCallback } from "react";
import { TagBox } from "devextreme-react/tag-box";
import DataGrid, {
  Column,
  Editing,
  Pager,
  RequiredRule,
  CustomRule,
  Paging,
  Lookup,
  RangeRule,
  Button as GridButton,
} from "devextreme-react/data-grid";
import { getEmployees, deleteEmployee, createEmployee, updateEmployee } from "../../api/employees";
import { getContracts } from "../../api/contracts";
import { getBusinessUnits } from "../../api/bunits";
import { formatMessage } from "devextreme/localization";
import BusinessUnitDisplay from "./BusinessUnitDisplay";

const EmployeePage = () => {
  const [employees, setEmployees] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);

  useEffect(() => {
    getBusinessUnits()
      .then((data) => setBusinessUnits(data))
      .catch((error) => console.error("Error fetching business units:", error));
  }, []);

  useEffect(() => {
    getContracts()
      .then((data) => setContracts(data))
      .catch((error) => console.error("Error fetching contracts:", error));
  }, []);

  useEffect(() => {
    getEmployees()
      .then((data) => setEmployees(data))
      .catch((error) => console.error("Error fetching employees:", error));
  }, []);

  const handleDelete = (e) => {
    const deletedEmployee = e.data;
    deleteEmployee(deletedEmployee.employeeID)
      .then(() => setEmployees((prevState) => prevState.filter((employee) => employee.employeeID !== deletedEmployee.employeeID)))
      .catch((error) => console.error("Error deleting employee:", error));
  };

  const handleCreate = (e) => {
    const newEmployee = e.data;
    newEmployee.businessUnits = e.data.businessUnits || [];
    createEmployee(newEmployee);
  };

  const handleCopyRow = (rowData) => {
    const newEmployee = { ...rowData };
    newEmployee.businessUnits = rowData.businessUnits || [];

    createEmployee(newEmployee)
      .then((createdEmployee) => {
        setEmployees((prevState) => [...prevState, createdEmployee]);
        getEmployees().then((data) => {
          setEmployees(data);
        });
      })
      .catch((error) => {
        console.error("Error copying employee:", error);
      });
  };

  const handleSaveChanges = (e) => {
    const updatedEmployee = e.data;
    const id = updatedEmployee.employeeID;
    updatedEmployee.businessUnits = e.data.businessUnits || [];
    updateEmployee(updatedEmployee, id);
  };
  
  const validateBusinessUnits = (options) => {
    console.log('Data check:', {
      dataKeys: Object.keys(options.data || {}),
      businessUnits: options.data?.businessUnits,
      fullData: options.data
    });
  
    if (Object.keys(options.data || {}).length === 1 && 
        Array.isArray(options.data?.businessUnits) && 
        options.data.businessUnits.length === 0) {
      console.log('Detected new record');
      return true;
    }
    
    const value = options.value || [];
    console.log('Checking value:', value);
    return Array.isArray(value) && value.length > 0;
  };
  


  const BusinessUnitEditorTemplate = ({ data, value, setValue }) => {
    // Initialize defaultValue with current value or empty array
    const [selectedValues, setSelectedValues] = useState(value || []);

    // Update local state and parent when value changes
    const handleValueChange = (e) => {
      const newValue = e.value;
      setSelectedValues(newValue);
      setValue(newValue);
    };

    // Update local state if parent value changes
    useEffect(() => {
      setSelectedValues(value || []);
    }, [value]);

    return (
      <TagBox
        dataSource={businessUnits}
        value={selectedValues}
        defaultValue={selectedValues}
        valueExpr="businessUnitID"
        displayExpr="name"
        showSelectionControls={true}
        maxDisplayedTags={2}
        applyValueMode="useButtons"
        showClearButton={true}
        onValueChanged={handleValueChange}
      />
    );
  };

  return (
    <div className={"content-block"}>
      <h2>{formatMessage("employees")}</h2>
      <DataGrid
        dataSource={employees}
        keyExpr="employeeID"
        className={"dx-card wide-card"}
        focusedRowEnabled={true}
        columnAutoWidth={true}
        rowAlternationEnabled={true}
        columnHidingEnabled={true}
        onRowRemoved={handleDelete}
        onRowInserted={handleCreate}
        onRowUpdated={handleSaveChanges}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <Editing
          mode="popup"
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
          popup={{
            title: formatMessage("editEmployee"),
            showTitle: true,
            width: 600,
            height: 550,
          }}
        />
        <Column dataField="firstName" caption={formatMessage("firstName")}>
          <RequiredRule />
        </Column>
        <Column dataField="lastName" caption={formatMessage("lastName")}>
          <RequiredRule />
        </Column>
        <Column dataField="contractID" caption={formatMessage("contract")}>
          <Lookup dataSource={contracts} valueExpr="contractId" displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column 
          dataField="businessUnits" 
          caption={formatMessage("unit")} 
          allowSorting={false}
          cellRender={(cellData) => (
            <BusinessUnitDisplay
              value={cellData.value}
              businessUnits={businessUnits}
            />
          )}
          editCellRender={BusinessUnitEditorTemplate}
        >
          <CustomRule
            validationCallback={validateBusinessUnits}
            message={formatMessage("businessUnitValidationMsg")}
          />

        </Column>
        <Column dataField="skillRate" caption={formatMessage("skillRate")} dataType="number">
          <RequiredRule />
          <RangeRule min={0} />
        </Column>
        <Column type="buttons">
          <GridButton name="edit" />
          <GridButton icon="copy" hint={formatMessage("copy")} onClick={(e) => handleCopyRow(e.row.data)} />
          <GridButton name="delete" />
        </Column>
      </DataGrid>
    </div>
  );
};

export default EmployeePage;