import { useRef, useState, useCallback, useMemo, useEffect } from "react";

import { useScreenSize } from "../../utils/media-query";
import { findAllAppointmentsForDay } from "./utils";
import { deleteShift, createShift, updateShift } from "../../api/shifts";
import { getBusinessUnits } from "../../api/bunits";
import { getAllShifts, getEmployeesMonthHours } from "../../api/shifts";
import { confirm } from "devextreme/ui/dialog";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
import service from "../../dictionary.js";
export const useSchedulerLogic = () => {
  const { isXSmall, isLarge } = useScreenSize();
  const tooltipRef = useRef(null);
  const schedulerRef = useRef(null);
  const [agendaItems, setAgendaItems] = useState([]);
  const [currentView, setCurrentView] = useState(
    localStorage.getItem("currentView") || "workWeek"
  );
  const [date, setDate] = useState(new Date());
  const [schedulerCurrentDate, setSchedulerCurrentDate] = useState(new Date());
  const [rightPanelOpen, setRightPanelOpen] = useState(true);

  const [selectedAppointment, setSelectedAppointment] = useState({
    data: null,
    target: null,
  });
  const [tasks, setTasks] = useState(null);
  const [calendars, setCalendars] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [shifts, setShifts] = useState([]);

  const currentLocale = sessionStorage.getItem("locale") || "en";
  locale(currentLocale);
  loadMessages(service.getDictionary());

  const fetchShiftsEmployees = useCallback(async () => {
    try {
      const data = await getEmployeesMonthHours();
      setEmployees(data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  }, []);

  const fetchBusinessUnits = useCallback(async () => {
    try {
      const data = await getBusinessUnits();
      const calendarBUnits = data.map((item) => ({
        id: item.businessUnitID,
        text: item.name,
      }));
      setBusinessUnits(calendarBUnits);
      const defCalendarListItems = [
        {
          key: formatMessage("businessUnits"),
          items: calendarBUnits,
        },
      ];
      setCalendars(defCalendarListItems);
    } catch (error) {
      console.error("Error fetching business units:", error);
    }
  }, []);

  const fetchAllShifts = useCallback(async () => {
    try {
      const data = await getAllShifts();
      setShifts(data);
    } catch (error) {
      console.error("Error fetching shifts:", error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchShiftsEmployees();
      await fetchBusinessUnits();
      await fetchAllShifts();
    };

    fetchData();
  }, [fetchShiftsEmployees, fetchBusinessUnits, fetchAllShifts]);

  const tooltipPosition = useMemo(() => {
    if (isXSmall) {
      return "bottom";
    }
    const classList =
      selectedAppointment?.target?.classList ||
      selectedAppointment?.target?.[0]?.classList;
    return classList && classList.contains("dx-list") && rightPanelOpen
      ? "left"
      : "top";
  }, [selectedAppointment, rightPanelOpen, isXSmall]);

  const toggleRightPanelOpen = useCallback(() => {
    setRightPanelOpen(!rightPanelOpen);
    /*if (isLarge) {
      schedulerRef.current?.instance.repaint();
    }*/
  }, [rightPanelOpen]);

  const onCurrentViewChange = useCallback(
    (view) => {
      /*if (view === "month" && !isXSmall) {
        setRightPanelOpen(true);
      }
      if (currentView === "month" && view !== "month") {
        setRightPanelOpen(false);
      }*/
      setCurrentView(view);
      localStorage.setItem("currentView", view);
    },
    []
  );

  const showAppointmentCreationForm = useCallback(() => {
    schedulerRef.current?.instance.showAppointmentPopup();
  }, []);

  const deleteCurrentAppointment = useCallback(() => {
    let result = confirm(
      formatMessage("deleteMsg"),
      formatMessage("delete")
    );
    result.then((dialogResult) => {
      if (dialogResult) {
        schedulerRef.current?.instance.deleteAppointment(
          selectedAppointment?.data
        );
        deleteShift(selectedAppointment?.data.id);
      }
    });
    tooltipRef.current?.instance.hide();
  }, [selectedAppointment]);

  const editCurrentAppointment = useCallback(() => {
    schedulerRef.current?.instance.showAppointmentPopup(
      selectedAppointment?.data,
      false
    );
    tooltipRef.current?.instance.hide();
  }, [selectedAppointment]);

  const updateAgenda = useCallback(
    (appointmentData) => {
      setAgendaItems(findAllAppointmentsForDay(appointmentData, tasks));
    },
    [tasks]
  );

  const onAppointmentClick = useCallback(
    (e) => {
      if (currentView === "month" && !rightPanelOpen) {
        const appointmentData = e.appointmentData;
        setSelectedAppointment({
          data: appointmentData,
          target: e.targetElement,
        });
        updateAgenda(appointmentData);
        toggleRightPanelOpen();
      }
    },
    [currentView, rightPanelOpen, updateAgenda, toggleRightPanelOpen]
  );

  const onAppointmentTooltipShowing = useCallback(
    (e) => {
      e.cancel = true;
      const appointmentData = e.appointments[0].appointmentData;

      const isAppointmentCollectorClicked = (e) => {
        return e.targetElement?.[0]?.classList.contains(
          "dx-scheduler-appointment-collector"
        );
      };

      setSelectedAppointment({
        data: appointmentData,
        target: e.targetElement,
      });

      if (currentView === "month" || isAppointmentCollectorClicked(e)) {
        updateAgenda(appointmentData);
      }
      if (
        ((currentView === "month" && isXSmall) ||
          isAppointmentCollectorClicked(e)) &&
        !rightPanelOpen
      ) {
        toggleRightPanelOpen();
      } else {
        tooltipRef.current?.instance.show();
      }
    },
    [currentView, isXSmall, rightPanelOpen, updateAgenda, toggleRightPanelOpen]
  );

  const setCurrentDate = useCallback(
    (selectedDate) => {
      const schedulerInstance = schedulerRef.current?.instance;
      const startViewDate = schedulerInstance?.getStartViewDate();
      const endViewDate = schedulerInstance?.getEndViewDate();

      if (
        schedulerCurrentDate.getMonth() !== selectedDate.getMonth() ||
        (startViewDate && startViewDate > selectedDate) ||
        (endViewDate && endViewDate < selectedDate)
      ) {
        setSchedulerCurrentDate(selectedDate);
        localStorage.setItem("optionDate", selectedDate);
      }
      setDate(selectedDate);
    },
    [schedulerCurrentDate]
  );

  const onSelectedDateChange = useCallback(
    (e) => {
      const date = e instanceof Date ? e : new Date();
      setDate(date);
      setCurrentDate(date);
      setSelectedAppointment({ data: { startDate: date }, target: undefined });
      updateAgenda({ startDate: date });
    },
    [updateAgenda, setCurrentDate]
  );

  const onAppointmentModified = useCallback(
    (e) => {
      if (
        e.appointmentData.startDate.toDateString() ===
        selectedAppointment?.data.startDate.toDateString()
      ) {
        updateAgenda(e.appointmentData);
      }
    },
    [selectedAppointment, updateAgenda]
  );

  const onAppointmentAdded = useCallback(
    (e) => {
      if (
        e.appointmentData.startDate.toDateString() ===
        selectedAppointment?.data.startDate.toDateString()
      ) {
        // updateAgenda(e.appointmentData);
        createShift(e.appointmentData) 
        .then(() => {
          return getAllShifts();
        })
        .then((data) => {
          setShifts(data);
        })
        .catch((error) => {
          console.error("Error updating shift:", error);
        });
      }
    },
    [selectedAppointment]
  );

  const onAppointmentUpdated = useCallback(
    (e) => {
      if (
        e.appointmentData.startDate.toDateString() ===
        selectedAppointment?.data.startDate.toDateString()
      ) {
        updateShift(e.appointmentData, e.appointmentData.id)
          .then(() => {
            return getAllShifts();
          })
          .then((data) => {
            setShifts(data);
          })
          .catch((error) => {
            console.error("Error updating shift:", error);
          });
      }
    },
    [selectedAppointment]
  );

  const showAppointmentTooltip = useCallback((e) => {
    schedulerRef.current?.instance.showAppointmentTooltip(
      e.itemData,
      e.element
    );
  }, []);

  const onCellClick = useCallback(
    (e) => {
      onSelectedDateChange(e.cellData.startDate);
      if (currentView === "month" && e.cellData) {
        const cellAppointments = findAllAppointmentsForDay(e.cellData, tasks);
        if (cellAppointments.length > 1) {
          setSelectedAppointment({ data: e.cellData, target: null });
          setAgendaItems(cellAppointments);
          if (!rightPanelOpen) {
            toggleRightPanelOpen();
          }
        }
      }
    },
    [currentView, rightPanelOpen, toggleRightPanelOpen, onSelectedDateChange]
  );

  return {
    agendaItems,
    currentView,
    date,
    rightPanelOpen,
    schedulerRef,
    schedulerCurrentDate,
    selectedAppointment,
    tooltipPosition,
    tooltipRef,
    showAppointmentCreationForm,
    deleteCurrentAppointment,
    fetchShiftsEmployees,
    editCurrentAppointment,
    onCurrentViewChange,
    onAppointmentClick,
    onAppointmentTooltipShowing,
    onAppointmentModified,
    onAppointmentUpdated,
    onAppointmentAdded,
    onCellClick,
    onSelectedDateChange,
    showAppointmentTooltip,
    toggleRightPanelOpen,
    employees,
    businessUnits,
    shifts,
    calendars,
  };
};
